import { msalInstance } from '../index.tsx'

const GetRoles = () => {
  let roles = []
  const activeAccount = msalInstance.getActiveAccount()
  if (activeAccount) {
    roles = activeAccount?.idTokenClaims?.roles
  }
  return roles ? roles : []
}
const GetUserName = () => {
  let userDetails = []
  let userName = sessionStorage.getItem('userSessionName')
  let userEmail = sessionStorage.getItem('userSessionEmail')
  if (!userName && !userEmail) {
    userName = localStorage.getItem('userSessionName')
    userEmail = localStorage.getItem('userSessionEmail')
    sessionStorage.setItem('userSessionName', userName)
    sessionStorage.setItem('userSessionEmail', userEmail)
  }
  if (userName === 'null' || userEmail === 'null') {
    const activeAccount = msalInstance.getActiveAccount()
    console.log('active', activeAccount)
    if (activeAccount) {
      sessionStorage.setItem('userSessionName', activeAccount.name)
      localStorage.setItem('userSessionName', activeAccount.name)
      sessionStorage.setItem('userSessionEmail', activeAccount.username)
      localStorage.setItem('userSessionEmail', activeAccount.username)
      userName = activeAccount.name
      userEmail = activeAccount.username
    }
  }
  userDetails = [
    {
      userName: userName,
      userEmail: userEmail,
    },
  ]
  return userDetails
}

export { GetUserName, GetRoles }
