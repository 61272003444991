import { environmentData } from "../environments/env.ts";
import { msalInstance } from "../index.tsx";

const validJWTToken = async (token) => {
  try {
    const decodedJwt = JSON.parse(window.atob(token.split(".")[1]));
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

const GetAccessToken = async () => {
  try {
    let accessToken = await validJWTToken(
      sessionStorage.getItem("accessToken")
    );
    if (!accessToken) {
      accessToken = await validJWTToken(localStorage.getItem("accessToken"));
      sessionStorage.setItem(
        "accessToken",
        localStorage.getItem("accessToken")
      );
    }
    if (!accessToken) {
      const loginCurrentRequest = {
        scopes: [environmentData[environmentData.run]["api-scope"]],
      };
      let activeAccount = msalInstance.getActiveAccount();

      // Initialize page views count
      await msalInstance
        .acquireTokenSilent(loginCurrentRequest, activeAccount)
        .then((response) => {
          accessToken = response.accessToken;
          sessionStorage.setItem("accessToken", response.accessToken);
          localStorage.setItem("accessToken", response.accessToken);
        });
    }
    return sessionStorage.getItem("accessToken");
  } catch (e) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  }
};

export { GetAccessToken };
