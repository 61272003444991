export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  'api-scope': string;
  appInsightConnectionString: string;
}
export const environmentData = {
  run: 'development',

  development: {
    clientId: '9cef1d56-e041-4b61-b4da-db2f4d179b7b',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9cef1d56-e041-4b61-b4da-db2f4d179b7b/User.Impersonation',   
  },
  staging: {
    clientId: '9cef1d56-e041-4b61-b4da-db2f4d179b7b',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9cef1d56-e041-4b61-b4da-db2f4d179b7b/User.Impersonation', 
  },
  production: {
    clientId: '9cef1d56-e041-4b61-b4da-db2f4d179b7b',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9cef1d56-e041-4b61-b4da-db2f4d179b7b/User.Impersonation', 
  },
} as EnvironmentData
