import React from "react";
import styles from "./Store.module.css";
import { IconButton, Modal } from "@fluentui/react";
import { useId, useBoolean } from "@fluentui/react-hooks";
import Chat from "../chat/Chat";
const Store = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const [keepInBounds, { toggle: toggleKeepInBounds }] = useBoolean(false);
  const titleId = useId("title");
  return (
    <div className={styles.container}>
      <span className={styles.genAIChatIcon} onClick={showModal}></span>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        className={styles.chatModel}
      >
        <div className={styles.header}>
          <span className={styles.chatHeaderIcon}></span>
          <span className={styles.chatHeaderTitle}>
            Generative AI - Real Time Conversation
          </span>
          <span className={styles.chatCloseButton} onClick={hideModal}></span>
        </div>
        <Chat />
      </Modal>
    </div>
  );
};
export default Store;
