import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  FontIcon,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  DefaultEffects,
} from "@fluentui/react";
import { invokeApi } from "../../api";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";

interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  currentquestion: string;
}

export const Answer = ({
  answer,
  currentquestion,
  onCitationClicked,
}: Props) => {
  console.log("current questions", currentquestion);
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);

  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation.filepath.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation.filepath} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  return (
    <>
      <Stack
        className={styles.answerContainer}
        style={{ boxShadow: DefaultEffects.elevation16 }}
      >
        <Stack.Item grow>
          <ReactMarkdown
            linkTarget="_blank"
            remarkPlugins={[remarkGfm, supersub]}
            children={parsedAnswer.markdownFormatText}
            className={styles.answerText}
          />
        </Stack.Item>
        {/* {currentquestion.startsWith("INC") && (
          <div>
            {" "}
            <p className="answerText">Would you like me to close the ticket?</p>
            <DefaultButton className="answerTextButton" onClick={handleYesClick}>
              Yes
            </DefaultButton>
            <DefaultButton className="answerTextButton">No</DefaultButton>
          </div>
        )} */}
        <Stack horizontal className={styles.answerFooter}>
          {!!parsedAnswer.citations.length && (
            <Stack.Item aria-label="References">
              <Stack style={{ width: "100%" }}>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                >
                  <Text
                    className={styles.accordionTitle}
                    onClick={toggleIsRefAccordionOpen}
                  >
                    <span>
                      {parsedAnswer.citations.length > 1
                        ? parsedAnswer.citations.length + " references"
                        : "1 reference"}
                    </span>
                  </Text>
                  <FontIcon
                    className={styles.accordionIcon}
                    onClick={handleChevronClick}
                    iconName={
                      chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                    }
                  />
                </Stack>
              </Stack>
            </Stack.Item>
          )}
          {/* <Stack.Item className={styles.answerDisclaimerContainer}>
            <div>
              <span className={styles.answerDisclaimer}>
                HAI content may be incorrect
              </span>
            </div>
                </Stack.Item> */}
        </Stack>
        {chevronIsExpanded && (
          <div
            style={{
              marginTop: 8,
              display: "flex",
              flexFlow: "wrap column",
              maxHeight: "150px",
              gap: "4px",
            }}
          >
            {parsedAnswer.citations.map((citation, idx) => {
              return (
                <span
                  title={createCitationFilepath(citation, ++idx)}
                  key={idx}
                  onClick={() => onCitationClicked(citation)}
                  className={styles.citationContainer}
                >
                  <div className={styles.citation}>{idx}</div>
                  {createCitationFilepath(citation, idx, true)}
                </span>
              );
            })}
          </div>
        )}
      </Stack>
    </>
  );
};
