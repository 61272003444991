import { UserInfo, ConversationRequest } from "./models";
import { GetAccessToken } from "../services/AuthToken";
export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal,
  index: String
): Promise<Response> {
  let token = await GetAccessToken();
  const response = await fetch(
    "https://hm-chatgpt-model-app-api.azurewebsites.net/conversation",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        messages: options.messages,
        indexname: index,
      }),
      signal: abortSignal,
    }
  );

  return response;
}
export async function sendEmaiApi(powershellscript: string): Promise<Response> {
  const response = await fetch(
    "https://prod-249.westeurope.logic.azure.com:443/workflows/6da68e9aa06247ba89c0e1ae5f354ceb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OJtfDs-CVSEXnEUhgMH6PoEzJ0oC8FrF6n2XFNCfM9Q",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Script: powershellscript,
      }),
    }
  );

  return response;
}
export async function invokeApi(powershellscript: string): Promise<Response> {
  const response = await fetch(
    "https://prod-249.westeurope.logic.azure.com:443/workflows/6da68e9aa06247ba89c0e1ae5f354ceb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OJtfDs-CVSEXnEUhgMH6PoEzJ0oC8FrF6n2XFNCfM9Q",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Script: powershellscript,
      }),
    }
  );

  return response;
}

export async function invokeApi1(email: string): Promise<Response> {
  const response = await fetch(
    "https://prod-56.westeurope.logic.azure.com:443/workflows/364b171d25c74e5ba6ed84090bb4b391/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=omCukuvmVS2xD809QTgJTHTqC_Q9ExjqwYptf8xNAbY",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserEmail: email,
      }),
    }
  );

  return response;
}
export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch("/.auth/me");
  if (!response.ok) {
    console.log("No identity provider found. Access to chat will be blocked.");
    return [];
  }

  const payload = await response.json();
  return payload;
}
