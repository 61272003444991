import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  FontIcon,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  DefaultEffects,
} from "@fluentui/react";
import { invokeApi } from "../../api";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";

interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  currentquestion: string;
  onYesClicked: () => void;
  onNoClicked: () => void;
  email: string;
}

export const SystemAnswer = ({
  answer,
  currentquestion,
  onCitationClicked,
  onYesClicked,
  onNoClicked,
  email,
}: Props) => {
  console.log("current questions", currentquestion);
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);
  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation.filepath.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation.filepath} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  return (
    <>
      <Stack
        className={styles.answerContainer}
        style={{ boxShadow: DefaultEffects.elevation16 }}
      >
        <Stack.Item grow>
          <ReactMarkdown
            linkTarget="_blank"
            remarkPlugins={[remarkGfm, supersub]}
            children={parsedAnswer.markdownFormatText}
            className={styles.answerText}
          />
        </Stack.Item>

        <div className={styles.answerTextButtonDiv}>
          <DefaultButton
            style={{ boxShadow: DefaultEffects.elevation16 }}
            className={styles.answerTextButton}
            onClick={onYesClicked}
            hidden={email ? false : true}
          >
            ADD
          </DefaultButton>
          <DefaultButton
            style={{ boxShadow: DefaultEffects.elevation16 }}
            onClick={onNoClicked}
            className={styles.answerTextButtonCancel}
          >
            {email ? "No" : "Cancel"}
          </DefaultButton>
        </div>
      </Stack>
    </>
  );
};

export const SystemResponse = ({
  answer,
  currentquestion,
  onCitationClicked,
  onYesClicked,
}: Props) => {
  console.log("current questions", currentquestion);
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);
  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation.filepath.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation.filepath} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  return (
    <>
      <Stack className={styles.answerContainer}>
        <Stack.Item grow>
          <ReactMarkdown
            linkTarget="_blank"
            remarkPlugins={[remarkGfm, supersub]}
            children={parsedAnswer.markdownFormatText}
            className={styles.answerText}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};
