import { useRef, useState, useEffect } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import {
  DefaultButton,
  DefaultEffects,
  Dropdown,
  IDropdownOption,
  Label,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import {
  BroomRegular,
  DismissRegular,
  SquareRegular,
  ErrorCircleRegular,
  DeleteRegular,
  Speaker228Filled,
  SpeakerMute28Regular,
} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import styles from "./Chat.module.css";
import Azure from "../../assets/images/login-logo1.png";

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  invokeApi,
  sendEmaiApi,
  invokeApi1,
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import {
  SystemAnswer,
  SystemResponse,
} from "../../components/Answer/SystemAnswer";
import React from "react";

const Chat = () => {
  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);

  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
  const [isSpeechStarted, setIsSpeechStarted] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPsModalOpen, setIsPsModalOpen] = useState(false);
  const [smtpProvider, setSmtpProvider] = useState("smtp.hm.com");
  const [senderEmailID, setSenderEmailID] = useState("user1@hm.com");
  const [appPassword, setAppPassword] = useState("");
  const [receiverEmailID, setReceiverEmailID] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [psscript, setPsScript] = useState("");
  const [isToggled, setIsToggled] = useState(true);
  const [indexName, setIndexName] = useState(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const options: IDropdownOption[] = [
    { key: "yourdata-20230609092735-afc9-index-chunk", text: "EnterpriseDS" },
    { key: "genaiopsteps", text: "KnowledgeDS" },
    { key: "insightind", text: "Insight" },
    { key: "addmemberinedpoc2", text: "ActionDS" },
    { key: "insightindexnew", text: "Monitoring" },
  ];
  const indexKeyWord: { index: string; keywords: string[] }[] = [
    { index: "genaiopsteps", keywords: ["email", "powershell", "execute"] },
    { index: "insightind", keywords: ["handm", "h and m", "h&m","tcs","optumera","omnistore"] },
    { index: "addmemberinedpoc2", keywords: ["add member", "edpoc", "ED-Poc"] },
    { index: "insightindexnew", keywords: ["Monitoring"] },
  ];
  const SampleQuestion: { title: string; description: string }[] = [
    { title: "Incident Lists", description: "List down the Incidents" },
    {
      title: "Ed-POC Users Group",
      description: "Can you add my email id in ED-POC Users Group",
    },
    {
      title: "Store Status",
      description: "Can you share the status of the store?",
    },
    {
      title: "Powershell Script",
      description: "Create a sample powershell script for Increase size of VM",
    },
  ];
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const [spokenText, setSpokenText] = useState<string>("");
  const speechConfig = sdk.SpeechConfig.fromSubscription(
    "1221987474544498aeabb29a4ce8167c",
    "westeurope"
  );
  let player: any = null;
  //const synthesizer = new sdk.SpeechSynthesizer(speechConfig);

  // Declare a variable to keep track of the speech synthesis operation.
  let currentSynthesisOperation: any = null;

  const speakText = (text: string) => {
    if (!isMute) {
      player = new sdk.SpeakerAudioDestination();
      const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      const speechSynthesizer = new sdk.SpeechSynthesizer(
        speechConfig,
        audioConfig
      );

      speechSynthesizer.speakTextAsync(
        text.toString(),
        function (result) {
          console.log("Result");
          speechSynthesizer.close();
          setIsSpeechStarted(true);
          player.onAudioEnd = function () {
            console.log("speakTextAsync finished");
            setIsSpeechStarted(false);
          };
        },
        function (err) {
          speechSynthesizer.close();
        }
      );
    }
    // currentSynthesisOperation?.close();
  };

  // Function to stop the ongoing speech synthesis.
  const stopSpeaking = () => {
    setIsMute(true);
    if (player) {
      player.mute();
    }
  };
  // const stopSpeaking = () => {
  //   synthesizer.close();
  // };
  const handleDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      setSelectedOption(item.key as string);
      setIndexName(item.key as string);
      // Perform any other actions you want based on the selected value
    }
  };
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);
  const hidePsModal = () => setIsPsModalOpen(false);
  const _onchangeIndex = () => {
    if (!isToggled) {
      setIndexName("yourdata-20230609092735-afc9-index-chunk");
    } else {
      setIndexName("genaiopsteps");
    }
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };
  const _onchangeMAIndex = () => {
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };

  const showEmailModal = () => {
    console.log("Answers", answers);
    if (answers.length > 0 && answers[answers.length - 1].role == "assistant") {
      // Split the email template into lines
      const lines = answers[answers.length - 1].content.split("\n");
      const tempcontent = answers[answers.length - 1].content;
      const subjectIndex = tempcontent.indexOf("Subject:");

      // Extract the subject and content
      let subject = "";
      let content = "";

      if (subjectIndex !== -1) {
        subject = tempcontent
          .substring(
            subjectIndex + "Subject:".length,
            tempcontent.indexOf("\n", subjectIndex)
          )
          .trim();

        // Extract the content after the subject
        content = tempcontent
          .substring(tempcontent.indexOf("\n", subjectIndex) + 1)
          .trim();
      }

      setSubject(subject);
      setContent(content);
    }
    setIsModalOpen(true);
  };
  function getLastAssistantRole(data: any[]): any | null {
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].role === "assistant") {
        return data[i];
      }
    }
    return null;
  }
  const executePowershell = () => {
    let code = "";
    const lastAssistantRole = getLastAssistantRole(answers);
    if (answers.length > 0 && lastAssistantRole) {
      const tempcontent = lastAssistantRole.content;
      const codeBlockPattern = /```([\s\S]+?)```/g;

      // Extract code blocks using the regular expression
      const codeBlocks = tempcontent.match(codeBlockPattern);

      if (codeBlocks) {
        // Assuming there's only one code block in this example
        code = codeBlocks[0].replace(/```/g, "");
        console.log(code);
      } else {
        console.log("No code block found in the sentence.");
      }
      setPsScript(code);
      setIsPsModalOpen(true);
    }
  };
  const onExecute = async () => {
    console.log("Script", psscript);
    const response = await invokeApi(psscript);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Powershell Script Executed Successfully",
    };
    setAnswers([...answers, systemMessage]);
    hidePsModal();
  };
  const onSend = async () => {
    // Handle send logic here
    const script = `$smtpServer = "${smtpProvider}"
    $smtpPort = "587"
    $username = "${senderEmailID}"
    $password = "${appPassword}"
    $to = "${receiverEmailID}"
    $subject = "${subject}"
    $body = "${content}"
    $from =  "${senderEmailID}"
    
    $smtp = New-Object System.Net.Mail.SmtpClient($smtpServer, $smtpPort)
    $smtp.EnableSSL = $true
    $smtp.Credentials = New-Object System.Net.NetworkCredential($username, $password)
    $mailMessage = New-Object System.Net.Mail.MailMessage($from, $to, $subject, $body)
    $smtp.Send($mailMessage)`;
    console.log("Script", script);
    const response = await sendEmaiApi(script);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Email Sent Successfully",
    };
    setAnswers([...answers, systemMessage]);
    hideModal();
  };
  const getUserInfoList = async () => {
    const userInfoList = await getUserInfo();
    if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1") {
      setShowAuthMessage(true);
    } else {
      setShowAuthMessage(false);
    }
    //setShowAuthMessage(false);
  };
  const successFunc = async (ans: ChatMessage[], email: string) => {
    console.log("Success Function", ans, email);
    const response = await invokeApi1(email);
    console.log("Response", response);
    const updatedData = ans.map((obj) => {
      if (obj.role === "systemQuestion") {
        return {
          role: "systemAnswer",
          content: obj.email + " added successfully",
        };
      }
      return obj;
    });
    console.log("UpdatedData", updatedData);
    setAnswers(updatedData);
  };
  const cancelFunc = async (ans: ChatMessage[], email: string) => {
    console.log("Success Function", ans, email);
    const response = await invokeApi(email);
    console.log("Response", response);
    const updatedData = ans.filter((obj) => obj.role !== "systemQuestion");
    console.log("UpdatedData", updatedData);
    setAnswers(updatedData);
  };
  const noAnsFunc = async (ans: ChatMessage[], email: string) => {
    console.log("Success Function", ans, email);
    const response = await invokeApi(email);
    console.log("Response", response);
    // const updatedData = ans.filter((obj) => obj.role !== "systemQuestion");
    const updatedData = ans.map((obj) => {
      if (obj.role === "systemQuestion") {
        return {
          role: "systemQuestion",
          content:
            "If you want to add others email address, Please enter the email and send",
          callback: successFunc,
          noAnsCallback: cancelFunc,
          email: "",
        };
      }
      return obj;
    });
    console.log("UpdatedData", updatedData);
    setAnswers(updatedData);
  };
  const sendEmail = () => {
    console.log("Send Email", answers);
  };
  const getIndexName = (question: string) => {
    let idx = indexName;
    if (isToggled) {
      let tempidx = "";
      indexKeyWord.forEach((element) => {
        let hasMatch = element.keywords.some((word) => {
          let regex = new RegExp("\\b" + word + "\\b", "i");
          return regex.test(question);
        });
        if (hasMatch) {
          tempidx = element.index;
        }
      });
      idx = tempidx ? tempidx : "yourdata-20230609092735-afc9-index-chunk";
    } else {
      return indexName;
    }
    return idx;
  };
  const makeApiRequest = async (question: string) => {
    const regex = /@hm\.com\b/i;
    const lastanswer = answers[answers.length - 1];

    if (
      question.match(regex) &&
      lastanswer &&
      lastanswer.content ==
        "If you want to add others email address, Please enter the email and send"
    ) {
      const regex2 = /\b(\S+@hm\.com)\b/;
      const match = question.match(regex2);
      const wordWithHmEmail = match ? match[1] : "";
      const response = await invokeApi(wordWithHmEmail);
      console.log("Response", response);
      const updatedData = answers.map((obj) => {
        if (obj.role === "systemQuestion") {
          return {
            role: "systemAnswer",
            content: wordWithHmEmail + " added successfully",
          };
        }
        return obj;
      });
      console.log("UpdatedData", updatedData);
      setAnswers(updatedData);
    } else {
      lastQuestionRef.current = question;

      setIsLoading(true);
      setShowLoadingMessage(true);
      const abortController = new AbortController();
      abortFuncs.current.unshift(abortController);

      const userMessage: ChatMessage = {
        role: "user",
        content: question,
      };
      const filteredAnswers: ChatMessage[] = answers.filter(
        (item) => item.role !== "systemAnswer" && item.role !== "systemQuestion"
      );
      const request: ConversationRequest = {
        messages: [...filteredAnswers, userMessage],
      };
      console.log("Request", request);
      let result = {} as ChatResponse;
      try {
        let defaultques = [
          "ed-poc",
          "edpoc",
          "Enterprise dashboard access",
          "SOP for ED",
          "dashboard acces",
          "Enterprise dashboard",
          "SharePoint",
          "mailbox",
        ];
        let hasMatch = defaultques.some((word) => {
          let regex = new RegExp("\\b" + word + "\\b", "i");
          return regex.test(question);
        });
        let index = hasMatch
          ? "genaiopsteps"
          : "yourdata-20230609092735-afc9-index-chunk";
        let selectedIndex = getIndexName(question);
        const response = await conversationApi(
          request,
          abortController.signal,
          selectedIndex
        );
        console.log("response", response);
        if (response?.body) {
          // speakText("Hi How can I assit you?");
          const reader = response.body.getReader();
          let runningText = "";
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            var text = new TextDecoder("utf-8").decode(value);
            const objects = text.split("\n");
            console.log("Objects", objects);
            // let lastIndex = -1;

            // for (let i = objects.length - 1; i >= 0; i--) {
            //   let obj = JSON.parse(objects[i]);
            //   if (obj[i]?.choices[0]?.messages?.role === "tool") {
            //     lastIndex = i;
            //     break;
            //   }
            // }
            let lastmsg = "";
            objects.forEach((obj, i) => {
              try {
                runningText += obj;
                result = JSON.parse(runningText);
                console.log("result", result);
                setShowLoadingMessage(false);
                setAnswers([
                  ...answers,
                  userMessage,
                  ...result.choices[0].messages,
                ]);
                console.log("answers", answers);
                let responsemsg =
                  result &&
                  result.choices &&
                  result.choices[0] &&
                  result.choices[0].messages
                    ? result.choices[0].messages
                    : "";
                console.log("responsemsg.toString()", responsemsg.toString());
                runningText = "";
                console.log("containsRoleToolAndContent");
                // if (i == objects.length - 1) {
                let containsRoleToolAndContent = false;

                for (const choice of result.choices) {
                  for (const message of choice.messages) {
                    if (
                      message.role === "tool" &&
                      message.content.includes("AddMember.docx")
                    ) {
                      containsRoleToolAndContent = true;
                      break;
                    }
                  }
                }
                if (containsRoleToolAndContent) {
                  let userEmail =
                    sessionStorage.getItem("userSessionEmail") || "";
                  const systemMessage: ChatMessage = {
                    role: "systemQuestion",
                    content:
                      "Do you want to add your emailid " +
                      userEmail +
                      " in ED-POC-Users?",
                    callback: successFunc,
                    noAnsCallback: noAnsFunc,
                    email: userEmail,
                  };
                  setAnswers([
                    ...answers,
                    userMessage,
                    ...result.choices[0].messages,
                    systemMessage,
                  ]);
                  const regex = /@hm\.com\b/i;
                  if (question.match(regex)) {
                    const regex2 = /\b(\S+@hm\.com)\b/;
                    const match = question.match(regex2);
                    const wordWithHmEmail = match ? match[1] : null;
                    const systemMessage: ChatMessage = {
                      role: "systemQuestion",
                      content:
                        "Do you want to add " +
                        wordWithHmEmail +
                        " in ED-POC-Users?",
                      callback: successFunc,
                      noAnsCallback: noAnsFunc,
                      email: wordWithHmEmail ? wordWithHmEmail : "",
                    };
                    setAnswers([
                      ...answers,
                      userMessage,
                      ...result.choices[0].messages,
                      systemMessage,
                    ]);
                  }
                }
              } catch {}
            });
            console.log("result", result);
          }
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          console.error(e);
          console.error(result);
          alert(
            "An error occurred. Please try again. If the problem persists, please contact the site administrator."
          );
        }
        setAnswers([...answers, userMessage]);
      } finally {
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController
        );
        let finalmessage = "";
        result.choices[0].messages.forEach((element) => {
          if (element.role == "assistant") {
            finalmessage = element.content;
          }
        });
        if (finalmessage) {
          if (finalmessage.length <= 500) {
            speakText(finalmessage);
          }
        }
      }

      return abortController.abort();
    }
  };

  const clearChat = () => {
    lastQuestionRef.current = "";
    setActiveCitation(undefined);
    setAnswers([]);
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfoList();
  }, []);

  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }),
    [showLoadingMessage]
  );
  const onSampleQNClick = (question: string) => {
    makeApiRequest(question);
  };
  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation.content,
      citation.id,
      citation.title ?? "",
      citation.filepath ?? "",
      "",
      "",
    ]);
    setIsCitationPanelOpen(true);
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message.role === "tool") {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  return (
    <div className={styles.container}>
      {showAuthMessage ? (
        <Stack className={styles.chatEmptyState}>
          <ErrorCircleRegular
            className={styles.chatIcon}
            style={{ color: "crimson" }}
          />
          <h1 className={styles.chatEmptyStateTitle}>
            Authentication Not Configured
          </h1>
          <h2 className={styles.chatEmptyStateSubtitle}>
            This app does not have authentication configured. Please add an
            identity provider.
          </h2>
          <h2 className={styles.chatEmptyStateSubtitle}>
            Go to your app in the
            <a href="https://portal.azure.com/" target="_blank">
              {" "}
              Azure Portal{" "}
            </a>
            and follow
            <a
              href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
              target="_blank"
            >
              {" "}
              these instructions
            </a>
            .
          </h2>
        </Stack>
      ) : (
        <Stack horizontal className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            <div className={styles.chatContainerActionTitle}>
              {!isToggled && (
                <div className={styles.left}>
                  <Dropdown
                    className={styles.indexDropDown}
                    placeholder="Select an Index"
                    options={options}
                    selectedKey={selectedOption}
                    onChange={handleDropdownChange}
                  />
                </div>
              )}
              <div className={styles.right}>
                <Toggle
                  defaultChecked
                  onText="Auto - Routing"
                  offText="Manual Routing"
                  onChange={_onchangeMAIndex}
                />
              </div>
            </div>
            {/* <Pivot
              aria-label="Links of Tab Style Pivot Example"
              linkFormat="tabs"
            >
              <PivotItem headerText="Knowledge DS">
                <Label>Knowledge DS</Label>
              </PivotItem>
              <PivotItem headerText="Enterprise DS">
                <Label>Enterprise DS</Label>
              </PivotItem>
            </Pivot> */}
            {/* <Toggle
              label="Index"
              defaultChecked
              onText="yourdata-20230609092735-afc9-index-chunk"
              offText="genaiopsteps"
              onChange={_onchangeIndex}
            /> */}
            {!lastQuestionRef.current ? (
              <Stack className={styles.chatEmptyState}>
                <div className={styles.chatDefaultHeading}>
                  <h1 className={styles.chatEmptyStateTitle}>
                    Transforming end-user interaction through intelligent Gen
                    AI.
                  </h1>
                  <h2 className={styles.chatEmptyStateSubtitle}>
                    Self-Sustaining Organizational Excellence
                  </h2>
                  <div className={styles.chatbackgroundimage}></div>
                </div>
                <div className={styles.gridcontainer}>
                  {SampleQuestion.map((item, index) => (
                    <div
                      key={index}
                      className={styles.griditem}
                      onClick={() => onSampleQNClick(item.description)}
                    >
                      <h6>{item.title}</h6>
                      <div>{item.description}</div>
                    </div>
                  ))}
                </div>
              </Stack>
            ) : (
              <div
                className={styles.chatMessageStream}
                style={{ marginBottom: isLoading ? "40px" : "0px" }}
              >
                {answers.map((answer, index) => (
                  <>
                    {answer.role === "user" ? (
                      <div className={styles.chatMessageUser}>
                        <div className={styles.chatMessageUserMessage}>
                          {answer.content}
                        </div>
                      </div>
                    ) : answer.role === "assistant" ? (
                      <div className={styles.chatMessageGpt}>
                        <Answer
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(
                              answers[index - 1]
                            ),
                          }}
                          currentquestion={lastQuestionRef.current}
                          onCitationClicked={(c) => onShowCitation(c)}
                        />
                      </div>
                    ) : answer.role === "systemQuestion" ? (
                      <div className={styles.chatMessageGpt}>
                        <SystemAnswer
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(
                              answers[index - 1]
                            ),
                          }}
                          currentquestion={lastQuestionRef.current}
                          onCitationClicked={(c) => onShowCitation(c)}
                          onYesClicked={() => {
                            if (answer.callback) {
                              answer.callback(answers, answer.email);
                            }
                          }}
                          onNoClicked={() => {
                            if (answer.noAnsCallback) {
                              answer.noAnsCallback(answers, answer.email);
                            }
                          }}
                          email={answer.email ? answer.email : ""}
                        />
                      </div>
                    ) : answer.role === "systemAnswer" ? (
                      <div className={styles.chatMessageGpt}>
                        <SystemResponse
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(
                              answers[index - 1]
                            ),
                          }}
                          currentquestion={lastQuestionRef.current}
                          onCitationClicked={(c) => onShowCitation(c)}
                          onYesClicked={() => {
                            if (answer.callback) {
                              answer.callback();
                            }
                          }}
                          onNoClicked={() => {
                            if (answer.noAnsCallback) {
                              answer.noAnsCallback(answers, answer.email);
                            }
                          }}
                          email={answer.email ? answer.email : ""}
                        />
                      </div>
                    ) : null}
                  </>
                ))}
                {showLoadingMessage && (
                  <>
                    <div className={styles.chatMessageUser}>
                      <div className={styles.chatMessageUserMessage}>
                        {lastQuestionRef.current}
                      </div>
                    </div>
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        answer={{
                          answer: "Generating answer...",
                          citations: [],
                        }}
                        currentquestion={""}
                        onCitationClicked={() => null}
                      />
                    </div>
                  </>
                )}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}

            <Stack horizontal className={styles.chatInput}>
              {isLoading && (
                <Stack
                  horizontal
                  className={styles.stopGeneratingContainer}
                  role="button"
                  aria-label="Stop generating"
                  tabIndex={0}
                  onClick={stopGenerating}
                  onKeyDown={(e) =>
                    e.key === "Enter" || e.key === " " ? stopGenerating() : null
                  }
                >
                  <SquareRegular
                    className={styles.stopGeneratingIcon}
                    aria-hidden="true"
                  />
                  <span
                    className={styles.stopGeneratingText}
                    aria-hidden="true"
                  >
                    Stop generating
                  </span>
                </Stack>
              )}
              <div className={styles.chatcontainer}>
                <div className={styles.answerTextButtonDiv}>
                  {/* DefaultButton at the top */}
                  <DefaultButton
                    style={{ boxShadow: DefaultEffects.elevation16 }}
                    className={styles.AnswerTexButton}
                    onClick={showEmailModal}
                  >
                    Send Email
                  </DefaultButton>
                  <DefaultButton
                    style={{ boxShadow: DefaultEffects.elevation16 }}
                    onClick={executePowershell}
                    className={styles.AnswerTexButton}
                  >
                    Execute Powershell
                  </DefaultButton>
                  {/* <DefaultButton
                    style={{ boxShadow: DefaultEffects.elevation16 }}
                    className={styles.AnswerTexButton}
                    onClick={stopSpeaking}
                  >
                    Stop Speaking
                  </DefaultButton> */}
                  {!isMute ? (
                    <Speaker228Filled
                      onClick={() => stopSpeaking()}
                    ></Speaker228Filled>
                  ) : (
                    <SpeakerMute28Regular
                      onClick={() => setIsMute(false)}
                    ></SpeakerMute28Regular>
                  )}
                </div>

                {/* Other two divs at the bottom */}
                <div className={styles.bottomDiv}>
                  <span
                    className={styles.clearChatBroomSpriteIcon}
                    onClick={clearChat}
                    onKeyDown={(e) =>
                      e.key === "Enter" || e.key === " " ? clearChat() : null
                    }
                    aria-label="Clear session"
                    role="button"
                    tabIndex={0}
                  ></span>
                  {/* <DeleteRegular
                    className={styles.clearChatBroom}
                    style={{
                      background:
                        isLoading || answers.length === 0
                          ? "#BDBDBD"
                          : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                      cursor:
                        isLoading || answers.length === 0 ? "" : "pointer",
                    }}
                    onClick={clearChat}
                    onKeyDown={(e) =>
                      e.key === "Enter" || e.key === " " ? clearChat() : null
                    }
                    aria-label="Clear session"
                    role="button"
                    tabIndex={0}
                  /> */}
                  <QuestionInput
                    clearOnSend
                    placeholder="Enter your question here..."
                    disabled={isLoading}
                    onSend={(question) => makeApiRequest(question)}
                    voicedisabled={isSpeechStarted || isLoading}
                  />
                </div>
              </div>
            </Stack>
          </div>
          {answers.length > 0 && isCitationPanelOpen && activeCitation && (
            <Stack.Item className={styles.citationPanel}>
              <Stack
                horizontal
                className={styles.citationPanelHeaderContainer}
                horizontalAlign="space-between"
                verticalAlign="center"
              >
                <span className={styles.citationPanelHeader}>Citations</span>
                <DismissRegular
                  className={styles.citationPanelDismiss}
                  onClick={() => setIsCitationPanelOpen(false)}
                />
              </Stack>
              <h5 className={styles.citationPanelTitle}>{activeCitation[2]}</h5>
              <ReactMarkdown
                linkTarget="_blank"
                className={styles.citationPanelContent}
                children={activeCitation[0]}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            </Stack.Item>
          )}
        </Stack>
      )}
      <div>
        <Modal
          titleAriaId="modalTitle"
          isOpen={isModalOpen}
          onDismiss={hideModal}
          isBlocking={false}
          styles={{
            main: { width: 800 },
            scrollableContent: { padding: 20 }, // Set the padding for the modal content
          }}
        >
          <div>
            <h2 id="modalTitle">Send Email</h2>
          </div>

          <div>
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <TextField
                  label="SMTP Provider"
                  required
                  defaultValue={smtpProvider}
                  onChange={(e, newValue) => setSmtpProvider(newValue || "")}
                />
                <TextField
                  label="Sender EmailID"
                  required
                  defaultValue={senderEmailID}
                  onChange={(e, newValue) => setSenderEmailID(newValue || "")}
                />
                <TextField
                  type="password"
                  label="App Password"
                  required
                  defaultValue={appPassword}
                  onChange={(e, newValue) => setAppPassword(newValue || "")}
                />
                <TextField
                  label="Receiver EmailID"
                  required
                  defaultValue={receiverEmailID}
                  onChange={(e, newValue) => setReceiverEmailID(newValue || "")}
                />
              </Stack>
              <TextField
                label="Subject"
                required
                defaultValue={subject}
                onChange={(e, newValue) => setSubject(newValue || "")}
              />
              <TextField
                label="Content"
                required
                multiline
                rows={10}
                defaultValue={content}
                onChange={(e, newValue) => setContent(newValue || "")}
              />
            </Stack>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <PrimaryButton onClick={onSend} style={{ marginRight: "10px" }}>
              Send
            </PrimaryButton>
            <DefaultButton onClick={hideModal}>Cancel</DefaultButton>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          titleAriaId="psmodalTitle"
          isOpen={isPsModalOpen}
          onDismiss={hidePsModal}
          isBlocking={false}
          styles={{
            main: { width: 800 },
            scrollableContent: { padding: 20 }, // Set the padding for the modal content
          }}
        >
          <div>
            <h2 id="modalTitle">Execute PowershellScript</h2>
          </div>

          <div>
            <Stack tokens={{ childrenGap: 10 }}>
              <TextField
                label="Script"
                required
                multiline
                rows={10}
                defaultValue={psscript}
                onChange={(e, newValue) => setPsScript(newValue || "")}
              />
            </Stack>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <PrimaryButton onClick={onExecute} style={{ marginRight: "10px" }}>
              Execute
            </PrimaryButton>
            <DefaultButton onClick={hidePsModal}>Cancel</DefaultButton>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Chat;
